import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./index.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { Menu as Menus } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { removeToCart } from "../../reducers/authReducer";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import { useForm } from "react-hook-form";
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { useMediaQuery } from 'react-responsive'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
function HeaderMenu(props) {
  const dispatch = useDispatch();
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  function handleClick(e) {
    console.log('', e);
  }
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const history = useHistory();
  const location = useLocation();

  const { number } = useParams();

  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  function userLogout() {
    dispatch(removeToCart());
    history.push(`/`);
  }

  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };



  const thisPath = window.location.pathname?.split('/')
  thisPath?.pop()
  const thisPathSplit = thisPath?.join('/')
  const thisPathName = thisPathSplit?.split('/')
  thisPathName?.pop()

  function displayMenu() {
    return (
      <div className="dFlex mobile-hide" style={{ margin: '0 10px' }}>
        <div className="dFlex">
          <span style={{ display: 'flex', alignItems: 'center', fontWeight: 400, color: '#5e6f82', fontSize: '1rem', textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
            {
              thisPath?.includes('box') ? 
              thisPathName?.join('/')?.replace(/-/g, ' ')?.slice(1).replace(/\//g, ' > ') : window.location.pathname?.replace(/-/g, ' ')?.startsWith('/') ?
                 window.location.pathname?.replace(/-/g, ' ')?.slice(1).replace(/\//g, ' > ')
                : window.location.pathname?.replace(/-/g, ' ')?.replace(/\//g, ' > ')
            }
            {number &&
              <span style={{ margin: '0px 5px' }}>{`>`} {number}</span>
            }
          </span>
        </div>
      </div>
    )
  }


  function onSubmit(data) {
    history.push(`/product/${data.product}`);
    reset()
  }


  function setNevActive(path) {
    if (window.location.pathname?.includes(path)) {
      return true
    } else {
      return false
    }
  }

  function setNevActived(path) {
    if (window.location.pathname === '/') {
      return true
    } else {
      return false
    }
  }


  function changeUrl(path) {
    history.push(path)
    if (isMobcreen) {
      props.toggleCollapsed(true)
    }
  }



  const items = [
    {
      key: 0,
      title: '',
      label:
        <Button
          style={{ position: 'fixed', top: '0px', zIndex: 1, background: 'white', width: !props.collapsed ? '234px' : '64px', height: '56px' }}
          disabled
          variant={'plain'}
          className={`thisMenu lg-m-menu`}
        >
          <svg width="18" height="18" fill="#ee6a12" className="bi bi-person-workspace" viewBox="0 0 16 16">
            <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
            <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.4 5.4 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              {userEmail}
            </span>
          }
        </Button>
    },
    {
      key: 1,
      label:
        <Button
          variant={setNevActived('/') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActived('/ ') && `thiActive`}`}
          onClick={(() => changeUrl('/'))}
        >
          <svg width="20" height="20" fill={`#5e6f82`} className="bi bi-grid" viewBox="0 0 16 16">
            <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Dashboard
            </span>
          }
        </Button>
    },
    {
      key: 2,
      label:
        <Button
          variant={setNevActive('inventory') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('inventory') && `thiActive`}`}
          onClick={(() => changeUrl('/inventory'))}
        >
          <WarehouseOutlinedIcon style={{ color: '#5e6f82', fontSize: '1.3rem' }} />
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Inventory</span>}
        </Button>
    },
    {
      key: 222,
      label:
        <Button
          variant={setNevActive('box') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('box') && `thiActive`}`}
          onClick={(() => changeUrl('/boxes'))}
        >
          <svg width="17" height="17" fill="#5e6f82" class="bi bi-box" viewBox="0 0 16 16">
            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Box</span>}
        </Button>
    },
    {
      key: 3,
      label:
        <Button
          variant={setNevActive('google-merchant') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('google-merchant') && `thiActive`}`}
          onClick={(() => changeUrl('/google-merchants'))}
        >
          <svg width="17" height="17" fill={`${'#5e6f82'}`} className="bi bi-google" viewBox="0 0 16 16">
            <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Google Merchant
            </span>
          }
        </Button>
    },
    {
      key: 4,
      label:
        <Button
          variant={setNevActive('coupon') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('coupon') && `thiActive`}`}
          onClick={(() => changeUrl('/coupons'))}
        >
          <svg width="20" height="20" fill={`${'#5e6f82'}`} className="bi bi-tag" viewBox="0 0 16 16">
            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0" />
            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1m0 5.586 7 7L13.586 9l-7-7H2z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Coupons</span>}
        </Button>
    },
    {
      key: 5,
      label:
        <Button
          variant={setNevActive('store') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('store') && `thiActive`}`}
          onClick={(() => changeUrl('/stores'))}
        >
          <svg width="20" height="20" fill={`${'#5e6f82'}`} className="bi bi-view-list" viewBox="0 0 16 16">
            <path d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2m0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2m0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Stores</span>}
        </Button>
    },
    {
      key: 6,
      label:
        <Button
          variant={setNevActive('product') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('product') && `thiActive`}`}
          onClick={(() => changeUrl('/products'))}
        >
          <svg width="20" height="20" fill={`${'#5e6f82'}`} className="bi bi-list" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Products</span>}
        </Button>
    },
    {
      key: 7,
      label:
        <Button
          variant={setNevActive('order') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('order') && `thiActive`}`}
          onClick={(() => changeUrl('/orders'))}
        >
          <svg width="18" height="18" fill={`${'#5e6f82'}`} className="bi bi-shop-window" viewBox="0 0 16 16">
            <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5m2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Orders</span>}
        </Button>,
      //  children: [
      //   {
      //     key: '5',
      //     label: <Button
      //       variant={setNevActive('order') ? `soft` : 'plain'}
      //       className={`thisMenu ${setNevActive('order') && `thiActive`}`}
      //       onClick={(() => changeUrl('/orders'))}
      //     >
      //       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#aaa" className="bi bi-record-fill" viewBox="0 0 16 16">
      //         <path fill-rule="evenodd" d="M8 13A5 5 0 1 0 8 3a5 5 0 0 0 0 10" />
      //       </svg>
      //       {!props.collapsed &&
      //         <span style={{ margin: '0px 10px', fontSize: '14px' }} className='admin-email'>
      //           Orders list</span>}
      //     </Button>
      //   },
      // ],
    },
    {
      key: 8,
      label:
        <Button
          variant={setNevActive('items') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('items') && `thiActive`}`}
          onClick={(() => changeUrl('/items'))}
        >
          <svg width="20" height="20" fill={`${'#5e6f82'}`} className="bi bi-list-check" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Order Items</span>}
        </Button>
    },
    {
      key: 88,
      label:
        <Button
          variant={setNevActive('shipments') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('shipment') && `thiActive`}`}
          onClick={(() => changeUrl('/shipments'))}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#5e6f82" className="bi bi-truck" viewBox="0 0 16 16">
            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Shipments</span>}
        </Button>
    },
    {
      key: 9,
      label:
        <Button
          variant={setNevActive('refunds') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('refunds') && `thiActive`}`}
          onClick={(() => changeUrl('/refunds'))}
        >
          <svg width="20" height="20" fill={`${'#5e6f82'}`} className="bi bi-list-check" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Refunds</span>}
        </Button>
    },
    {
      key: 10,
      label:
        <Button
          variant={setNevActive('countries') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('countries') && `thiActive`}`}
          onClick={(() => changeUrl('/countries/routeTwo'))}
        >
          <svg width="20" height="20" fill={`${'#5e6f82'}`} className="bi bi-flag" viewBox="0 0 16 16">
            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Countries</span>}
        </Button>
    },
    {
      key: 11,
      label:
        <Button
          variant={setNevActive('team') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('team') && `thiActive`}`}
          onClick={(() => changeUrl('/team'))}
        >
          <svg width="20" height="20" fill={`${'#5e6f82'}`} className="bi bi-person" viewBox="0 0 16 16">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Team</span>}
        </Button>
    },
    {
      key: 12,
      label:
        <Button
          variant={setNevActive('user') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('user') && `thiActive`}`}
          onClick={(() => changeUrl('/users'))}
        >
          <svg width="20" height="20" fill={`${'#5e6f82'}`} className="bi bi-people" viewBox="0 0 16 16">
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Users</span>}
        </Button>
    },
    {
      key: 13,
      label:
        <Button
          variant={setNevActive('media') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('media') && `thiActive`}`}
          onClick={(() => changeUrl('/media'))}
        >
          <svg width="17" height="17" fill={`${'#5e6f82'}`} className="bi bi-image" viewBox="0 0 16 16">
            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Media</span>}
        </Button>
    },
    {
      key: 14,
      title: '',
      disabled: true,
      label:
        <Button
          // onClick={(() => userLogout())}
          style={{ position: 'fixed', bottom: 0, zIndex: 1, background: 'white', display: 'flex', width: !props.collapsed ? '234px' : '64px', borderRadius: '0px', borderTop: '1px solid #eee' }}
          variant={setNevActive('media') ? `soft` : 'plain'}
          className={`thisMenu lg-m-menu`}
        >
          <svg width="18" height="18" fill="#5e6f82" className="bi bi-gear" viewBox="0 0 16 16">
            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
          </svg>
          {!props.collapsed &&
            <span className='admin-email' style={{ margin: '0px 10px' }}>
              Settings</span>}
        </Button>
    }
  ]

  return (
    <div className="ML-Header">

      <Menus
        onClick={handleClick}
        // mode="vertical" 
        mode="inline"
        inlineCollapsed={props.collapsed}
        className="sideMenuN side-menubar"
        items={items}
      >

      </Menus>
      <Navbar className={`topNavBar ${props.collapsed && 'menuCollapsed'}`} bg="white" expand="lg">
        <div className="addAllIconCard">
          <div>
            <span style={{ display: 'flex', alignItems: 'center' }} className={`syncIcon`} onClick={() => props.toggleCollapsed({ toggleCollapsed })}>
              {/* {React.createElement(MenuIcon)} */}
              <IconButton>
                {props.collapsed ?
                  <MenuOpenIcon style={{ color: '#5e6f82', fontSize: '1.6rem' }} /> :
                  <MenuIcon style={{ color: '#5e6f82', fontSize: '1.5rem' }} />
                }
              </IconButton>
            </span>
          </div>
          {displayMenu()}
        </div>
        <div className="headerRightCard">
          <form onSubmit={handleSubmit(onSubmit)} className="table_search_card">
            <input
              type="text"
              className="form-control table_search"
              placeholder="Search for product..."
              id="product"
              aria-invalid={errors.product ? "true" : "false"}
              {...register("product", { required: true })}
            />
            <IconButton style={{ borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
              <SearchIcon style={{ fontSize: 'inherit' }} />
            </IconButton>
          </form>

          <Dropdown>
            <MenuButton
              variant='soft'
              style={{ padding: '6px 8px', border: 'none', borderRadius: '6px', fontSize: '1rem' }}
              className="Header-button-connect">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#5e6e82" className="bi bi-gear-wide" viewBox="0 0 16 16">
                <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z" />
              </svg>
            </MenuButton>
            <Menu>
              <div style={{ padding: '10px 15px 10px 15px' }}>{userEmail}</div>
              <MenuItem
                key="1"
                component={Link}
                to={`/manage-keys`}
              >
                <div style={{ fontSize: '1rem' }}>Manage keys</div>
              </MenuItem>
              <MenuItem onClick={(() => userLogout())} key="2">
                <div style={{ fontSize: '1rem' }}>Logout</div>
              </MenuItem>
            </Menu>
          </Dropdown>

        </div>
      </Navbar >
    </div >
  );
}

export default HeaderMenu;
