import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import './index.scss';
import { useShowQuery, useUpdateMutation, useDeleteMutation, useFetchPriceMutation } from '../../services/products';
import { useSelector } from "react-redux";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import { CKEditor } from 'ckeditor4-react';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LinearProgress from '@mui/material/LinearProgress';
import Switch from '@mui/joy/Switch';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

function Product() {
  const classes = useStyles();
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { id } = useParams();

  const FetchPost = useShowQuery({ id: id, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const PostData = FetchPost?.data?.product;

  const [deleteList, deleteresult] = useDeleteMutation()

  const [getEditShow, setEditShow] = useState(false);

  const [getTitle, setTitle] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getColor, setColor] = useState("")
  const [getWeight, setWeight] = useState(0)
  const [getQuantity, setQuantity] = useState("")
  const [getBrand, setBrand] = useState("")
  const [getCondition, setCondition] = useState("")
  const [getInStock, setInStock] = useState("")
  const [getTags, setTags] = useState("")
  const [getPrice, setPrice] = useState(0)
  const [getCategories, setCategories] = useState("")
  const [getFeatures, setFeatures] = useState("")
  const [getDetails, setDetails] = useState("")
  const [getImageUrls, setImageUrls] = useState("")
  const [getProductUrl, setProductUrl] = useState("")
  const [getUID, setUID] = useState("")
  const [getDeliveryDays, setDeliveryDays] = useState(0)
  const [getId, setId] = useState()


  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setTitle(data.title)
    setUID(data.store_uid)
    setCountry(data.country)
    setColor(data.color)
    setWeight(data.weight)
    setQuantity(data.quantity)
    setBrand(data.brand)
    setCondition(data.condition)
    setInStock(data.in_stock)
    setPrice(data.price)
    setCategories(data.category)
    setTags(data.tags)
    setFeatures(data.features)
    setDetails(data.details)
    setImageUrls(data.images)
    setProductUrl(data.product_url)
    setDeliveryDays(data.estimated_delivery)
    setId(data.id)

  }

  const [updatePost, updateresult] = useUpdateMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
  }, [updateresult]);

  const postFeatures = (evt) => {
    const data = evt.editor.getData();
    setFeatures(data);
  }

  const postDetails = (evt) => {
    const data = evt.editor.getData();
    setDetails(data);
  }

  const updateData = {
    title: getTitle,
    country: getCountry,
    color: getColor,
    weight: getWeight,
    quantity: getQuantity,
    brand: getBrand,
    condition: getCondition,
    in_stock: getInStock,
    tags: getTags,
    price: getPrice,
    category: getCategories,
    features: getFeatures,
    details: getDetails,
    images: getImageUrls,
    product_url: getProductUrl,
    estimated_delivery: getDeliveryDays,
    userId: userId,
    userToken: userToken,
    admin: admin,
    id: getId,
    update: 'update'
  }


  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  const handleBack = () => {
    history.goBack();
  };

  function getLoadingProgress(loading1, loader2, loader3) {
    if (loading1) {
      return true
    } else if (loader2) {
      return true
    } else if (loader3) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div className="Order-style Post-style">
        <div style={{ display: 'none' }}>
          <CKEditor
            style={{ display: 'none' }}
            data={``}
            config={{
              extraPlugins: "justify,showblocks",
              toolbar: [
                ['Source'],
                ['Styles', 'Format', 'Font', 'FontSize'],
                ['Bold', 'Italic'],
                ['Undo', 'Redo'],
                ['EasyImageUpload'],
                ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                ['Maximize']
              ],
              height: '220px',
              allowedContent: false,
              direction: 'rtl',
              language: 'en',
              pasteFromWordRemoveFontStyles: false
            }}
            id="template_html"
          />
        </div>


        <Modal className="new-post-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={true}>
          <Modal.Body>

            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={handleCloseEdit}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Product</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Title</label>
                      <input type="text" className="form-control" defaultValue={getTitle} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Country</label>
                      <input readOnly type="text" className="form-control" defaultValue={getCountry} onChange={(e) => setCountry(e.target.value)} />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Price in USD</label>
                      <input type="number" className="form-control" defaultValue={getPrice} onChange={(e) => setPrice(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Weight in Grams</label>
                      <input type="number" className="form-control" defaultValue={getWeight} onChange={(e) => setWeight(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>UID</label>
                      <input type="text" disabled className="form-control" defaultValue={getUID} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Condition</label>
                      <input type="text" className="form-control" defaultValue={getCondition} onChange={(e) => setCondition(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Brand</label>
                      <input type="text" className="form-control" defaultValue={getBrand} onChange={(e) => setBrand(e.target.value)} />
                    </div>
                  </div>


                  <div className="col-md-4">
                    <div className="form-group">
                      <label>In Stock</label>
                      <select className='form-control' onChange={(e) => setInStock(e.target.value)}>
                        <option selected value={getInStock ? 'in_stock' : 'out_of_stock'}>{getInStock ? 'In Stock' : 'Out Of Stock'}</option>
                        <option value={'in_stock'}>In Stock</option>
                        <option value={'out_of_stock'}>Out of Stock</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Quantity</label>
                      <input type="number" className="form-control" defaultValue={getQuantity} onChange={(e) => setQuantity(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Delivery in Days</label>
                      <input type="number" className="form-control" defaultValue={getDeliveryDays} onChange={(e) => setDeliveryDays(e.target.value)} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Image urls</label>
                      <input type="text" className="form-control" defaultValue={getImageUrls} onChange={(e) => setImageUrls(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Features</label>
                      <input className="form-control" defaultValue={getFeatures} onChange={(e) => setFeatures(e.target.value)} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Details</label>
                      <CKEditor
                        data={``}
                        config={{
                          extraPlugins: "justify,showblocks",
                          toolbar: [
                            ['Source'],
                            ['Styles', 'Format', 'Font', 'FontSize'],
                            ['Bold', 'Italic'],
                            ['Undo', 'Redo'],
                            ['EasyImageUpload'],
                            ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                            ['Maximize']
                          ],
                          height: '220px',
                          allowedContent: false,
                          direction: 'rtl',
                          language: 'en',
                          pasteFromWordRemoveFontStyles: false
                        }}
                        id="template_html"
                        onChange={postDetails}
                        initData={getDetails} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Button disabled={updateresult?.isLoading ? true : false}
                        variant="soft"
                        size="sm"
                        style={{ fontWeight: 400 }}
                        onClick={() => { updatePost(updateData) }}
                      >
                        {updateresult?.isLoading ? 'Loading...' : 'Update'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <br />
        <div className="row" style={{ padding: 0, margin: 0 }}>
          <div className="col-md-12">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton variant='soft' size='sm' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
              <div className="post-card">
                <div className="post-value" style={{ margin: '0px 10px' }}>{PostData?.title}</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {getLoadingProgress(FetchPost?.isLoading, deleteresult?.isLoading, updateresult?.isLoading) &&
          <LinearProgress style={{ height: '2px' }} />
        }

        {FetchPost?.error?.originalStatus === 403 &&
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{FetchPost?.error?.data}</div>
          </div>
        }

        <div style={{ borderTop: '1px solid #eeee', borderBottom: '1px solid #eee', padding: '10px 0px 10px 0px' }}>
          <div className="row" style={{ margin: 0, padding: 0 }}>
            <div className="col-md-12">
              <ImageList className={classes.imageList} cols={8}>
                {PostData?.images?.split(',').map((concept, i) =>
                  <ImageListItem className="Dashboard-books-card" key={i} style={{ height: 'auto', padding: '10px' }}>
                    <div className="img-list-card" style={{ position: 'relative', position: 'relative', border: '1px solid #eeeeee78' }}>
                      <img className="img-list-img" style={{ width: '100%', border: '0.2px solid #000', borderRadius: '10px' }} src={`${concept}`} />
                      <div className="Category-text" style={{ position: 'absolute', top: 0, left: '0px', zIndex: 999 }}>
                      </div>
                    </div>
                  </ImageListItem>
                )}
              </ImageList>
            </div>
          </div>
        </div>

        <br />
        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12">
            <div>
              <div className="Order-Top_navbar">
                <div style={{ display: 'flex', whiteSpace: 'normal', width: '100%' }}>
                  <div className="post-section">
                    <div className="row">
                      <div className="col-md-12">
                        {PostData &&
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', padding: '20px 0px 20px 0px' }}>
                            <div className="form-group" style={{ margin: 0 }}>
                              <div className="post-title"><strong>Store Name</strong>&nbsp; {PostData?.store?.name} #{PostData?.store?.id}</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <IconButton
                                variant='outlined'
                                disabled={FetchPost?.isLoading}
                                onClick={() => handleOpenEdit(PostData)}
                              >
                                <EditIcon style={{ fontSize: '12px' }} />
                              </IconButton>
                              <IconButton
                                variant='outlined'
                                disabled={FetchPost?.isLoading}
                                style={{ margin: '0 10px' }}
                                onClick={() => window.open(`https://www.thwifty.com/product/${PostData.store_id}/${functionIdLink(PostData?.id, PostData?.pid, PostData?.store_id)}`, '_blank').focus()}
                              >
                                <LanguageIcon style={{ fontSize: '22px' }} />
                              </IconButton>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    {PostData &&
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '20px 0px 20px 0px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <label style={{ margin: 0 }}>Status</label>
                          <Switch
                            style={{ margin: '0px 5px' }}
                            checked={PostData?.status ? false : true}
                            onChange={() => deleteList({ status_update: true, status: PostData?.status === 0 ? 1 : 0, id: PostData?.id, userId: userId, userToken: userToken, admin: admin })}
                          />
                        </div>
                        <div style={{ margin: '0px 5px', display: 'flex', alignItems: 'center' }}>
                          <label style={{ margin: 0 }}>Trending</label>
                          <Switch
                            style={{ margin: '0px 5px' }}
                            checked={PostData?.in_search === 1 ? true : false}
                            onChange={() => deleteList({ in_search_update: true, in_search: PostData?.in_search === 0 ? 1 : 0, id: PostData?.id, userId: userId, userToken: userToken, admin: admin })}
                          />
                        </div>
                        <div style={{ margin: '0px 5px', display: 'flex', alignItems: 'center' }}>
                          <label style={{ margin: 0 }}>Popular</label>
                          <Switch
                            style={{ margin: '0px 5px' }}
                            checked={PostData?.popular === 1 ? true : false}
                            onChange={() => deleteList({ popular_update: true, popular: PostData?.popular === 0 ? 1 : 0, id: PostData?.id, userId: userId, userToken: userToken, admin: admin })}
                          />
                        </div>
                        <div style={{ margin: '0px 5px', display: 'flex', alignItems: 'center' }}>
                          <label style={{ margin: 0 }}>Google Merchant</label>
                          <Switch
                            style={{ margin: '0px 5px' }}
                            checked={PostData?.google_merchant === 1 ? true : false}
                            onChange={() => deleteList({ google_merchant_update: true, google_merchant: PostData?.google_merchant === 0 ? 1 : 0, id: PostData?.id, userId: userId, userToken: userToken, admin: admin })}
                          />
                        </div>
                        <div style={{ margin: '0px 5px', display: 'flex', alignItems: 'center' }}>
                          <label style={{ margin: 0 }}>In Stock</label>
                          <Switch
                            style={{ margin: '0px 5px' }}
                            checked={PostData?.in_stock === 1 ? true : false}
                            onChange={() => deleteList({ in_stock_update: true, in_stock: PostData?.in_stock === 0 ? 1 : 0, id: PostData?.id, userId: userId, userToken: userToken, admin: admin })}
                          />
                        </div>
                      </div>
                    }
                    <div className="post-card">
                      {FetchPost?.isLoading &&
                        <>
                          <Skeleton variant="rect" style={{ height: '12px' }} />
                          <Skeleton variant="rect" style={{ height: '12px' }} />
                          <Skeleton variant="rect" style={{ height: '12px', width: '50%' }} />
                          <Skeleton variant="rect" style={{ height: '12px', width: '50%' }} />
                          <Skeleton variant="rect" style={{ height: '12px', width: '20%' }} />
                        </>
                      }
                    </div>
                    {!FetchPost?.isLoading &&
                      <>
                        {PostData?.inventory ?
                          <div className='row' style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div className="post-card col-md-12" style={{ display: 'block' }}>
                              <span className="post-title"><strong>Inventory</strong></span>
                              <div className="badge badge-success" style={{ margin: '0px 10px' }}>Available</div>
                              <div className="badge badge-warning">{PostData?.qty}</div>
                            </div>
                          </div> : ''
                        }
                        <div className='row' style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>Product ID</strong></div>
                            <div className="post-value">{PostData?.id}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>PID</strong></div>
                            <div className="post-value">{PostData?.pid}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>Store UID</strong></div>
                            <div className="post-value">{PostData?.store_uid}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>Asin</strong></div>
                            <div className="post-value">{PostData?.asin}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>Price</strong></div>
                            <div className="post-value">{PostData?.currency} {PostData?.price}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>Country</strong></div>
                            <div className="post-value">{PostData?.country}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>Brand</strong></div>
                            <div className="post-value">{PostData?.brand}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>Condition</strong></div>
                            <div className="post-value">{PostData?.condition}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>In Stock</strong></div>
                            <div className="post-value">{PostData?.in_stock ? 'In Stock' : 'Out of stock'}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>estimated_delivery</strong></div>
                            <div className="post-value">{PostData?.estimated_delivery}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>weight</strong></div>
                            <div className="post-value">{PostData?.weight} {PostData?.weight_in}</div>
                          </div>
                          <div className="post-card col-md-3">
                            <div className="post-title"><strong>quantity</strong></div>
                            <div className="post-value">{PostData?.quantity}</div>
                          </div>
                        </div>
                        <div className="post-card">
                          <div className="post-title"><strong>Image Urls</strong></div>
                          <div className="post-value">{PostData?.images}</div>
                        </div>
                        <div className="post-card">
                          <div className="post-title"><strong>Features</strong></div>
                          <div className="post-value"
                            dangerouslySetInnerHTML={{
                              __html: PostData?.features
                            }}
                          >
                          </div>
                        </div>
                        <div className="post-card">
                          <div className="post-title"><strong>Details</strong></div>
                          <div className="post-value"
                            dangerouslySetInnerHTML={{
                              __html: PostData?.details
                            }}
                          >
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
