import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import AtmIcon from '@mui/icons-material/Atm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AppleIcon from '@mui/icons-material/Apple';


function OrdersList({ ordersData }) {

  const orders = ordersData?.data?.entities?.undefined?.orders;

  function shippingStatusC(data) {
    let color
    if (data === 'approved') {
      color = 'badge badge-success'
    } else if (data === 'cancelled') {
      color = 'badge badge-danger'
    } else if (data === 'fraud') {
      color = 'badge badge-danger'
    } else {
      color = 'badge badge-warning'
    }
    return { name: data?.replace(/_/g, ' '), color: color }
  }


  function borderColor(data) {
    let color
    if (data === 'approved') {
      color = '#00800108'
    } else if (data === 'cancelled') {
      color = '#cd201f05'
    } else {
      color = '#ffa5000a'
    }
    return color
  }
  function borderColorSticky(data) {
    let color
    if (data === 'delivered') {
      color = '#f2f4f0'
    } else if (data === 'cancelled') {
      color = '#f8f4f3'
    } else {
      color = '#f7f5ee'
    }
    return color
  }


  function getPaymentTypeIcon(type) {
    if (type === 'card') {
      return <CreditCardIcon style={{ fontSize: '15px' }} />
    } else if (type === 'apple_pay') {
      return <AppleIcon style={{ fontSize: '15px' }} />
    } else if (type === 'bank_transfer') {
      return <AtmIcon style={{ fontSize: '15px' }} />
    } else {
      return ''
    }
  }


  function getMessageCount(messages, message) {
    if (messages?.length > 0) {
      return true
    } else if (message?.length > 0) {
      return true
    } else {
      return false
    }
  }

 

  return (
    <>
      {orders?.map((item, i) =>
        <tr key={i} style={{ position: 'relative' }}>
          <div style={{
            position: 'absolute',
            right: 0,
            left: 0,
            top: 0,
            height: '100%',
            background: borderColor(item.states),
          }}>
          </div>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>

              <Link to={`/order/${item.order_number}`} style={{ position: 'relative', zIndex: 111, color: !item.status ? 'tomato' : '#5a5858' }}>
                {item.order_number}
              </Link>
              {getMessageCount(item.messages, item.message) &&
                <div disabled style={{ position: 'relative', margin: '0px 5px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-chat-square-dots" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                    <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                  </svg>
                  <span
                    className='badge badge-success'
                    style={{
                      position: 'absolute',
                      top: '-7px',
                      right: '-5px',
                      lineHeight: '12px',
                      borderRadius: '100px',
                      width: '12px',
                      height: '12px',
                      textAlign: 'center',
                      fontSize: '10px',
                      fontWeight: 400,
                      padding: '0px'
                    }}
                  >{item.messages ? Number(item.messages?.length) + Number(item.message?.length) : item.message?.length}</span>
                </div>
              }
            </div>
          </td>
          <td style={{ textAlign: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {getPaymentTypeIcon(item.payment_type)}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {item?.order_items?.map((order_items, index) =>
                  <Link key={index} className="orders-page-item-link" to={`/order/${item.order_number}`}
                    style={{ margin: '0 5px', border: `1px solid ${borderColor(item.shipping_status)}`, background: '#fff', padding: '3px', borderRadius: '4px', position: 'relative', zIndex: 111 }}>
                    <img src={`${order_items.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                    <span
                      style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                        lineHeight: '15px',
                        background: '#fe7f4a',
                        borderRadius: '100px',
                        width: '15px',
                        height: '15px',
                        textAlign: 'center',
                        fontSize: '12px',
                        color: '#fff',
                        fontWeight: 400,
                      }}
                    >{order_items.quantity}</span>
                  </Link>
                )}
              </div>
            </div>
          </td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {item.status ?
                <div>
                  {item.currency}  {Number(item.amount) + Number(item.shipping_price)}
                </div>
                :
                <span
                  className="alert alert-danger boot-alert-style">
                  {item.currency}  {Number(item.amount) + Number(item.shipping_price)}
                </span>
              }
            </div>
          </td>
          <td>
            <div className="dFlex center nowrap jcr">
              {item.checkout === 'failed' ? <span className='badge badge-danger'>Payment Failed</span> :
                <span
                  className={shippingStatusC(item.states)?.color}>
                  {shippingStatusC(item.states)?.name}
                </span>
              }
              <div style={{ margin: '0px 5px' }}>
                <span
                  className="alert boot-alert-style" style={{ color: !item.status && 'tomato' }}>
                  {item.country}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div style={{ position: 'relative', zIndex: 111, color: !item.status && 'tomato' }}>{item.domain}</div>
          </td>
          <td className="sticky-column" style={{ background: borderColorSticky(item.shipping_status) }}>
            <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right', color: !item.status && 'tomato' }}>
              {moment(item?.createdAt).format('llll')}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default OrdersList;
