import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import {  useFetchRefundQuery } from '../../services/refund';
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';

function Payby({ orderInfo, setUpdateStatus }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
 
  const refundInfo = useFetchRefundQuery({ id: orderInfo?.order?.order_number, type: 'payby', userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const refundData = refundInfo?.data?.refund?.body


  useEffect(() => {
    if (refundInfo?.isSuccess) {
      setUpdateStatus(true)
    }
  }, [refundInfo]);

  function getLinearProgress() {
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 111, height: '100%', background: '#ffffff82' }}>
        <LinearProgress style={{ height: '2px' }} />
      </div>
    )
  }


  return (
    <>
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12 list-card-op" style={{ position: 'relative' }}>
          <div className='details-card' style={{ position: 'relative', padding: '0px', border: 'none' }}>
            <div className="tabelOverflow details-card" style={{ position: 'relative', overflow: 'hidden', whiteSpace: 'normal', border: '1px solid #eee' }}>
              {refundInfo?.isLoading &&
                getLinearProgress()
              }
              <div className="row">
                <div className="col-md-12">
                  <h5 style={{ textAlign: 'left' }}>Refund details for Order {orderInfo?.order?.order_number}</h5>
                </div>
              </div>

              <br />
              {!refundInfo?.isLoading &&
                <>
                  {refundData ?
                    <div style={{ background: '#fcfcfc', borderRadius: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>

                        <div>Reason: &nbsp;</div>
                        <div>{refundData?.refundOrder?.reason}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                        <div>Status: &nbsp;</div>
                        <div>{refundData?.refundOrder?.status}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                        <div>Amount: &nbsp;</div>
                        <div>{refundData?.refundOrder?.amount?.amount}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                        <div>Currency: &nbsp;</div>
                        <div>{refundData?.refundOrder?.amount?.currency}</div>
                      </div>
                    </div> :
                    <div>No refund initiated</div>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

export default Payby;
