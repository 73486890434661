import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Users from './components/Users';
import Login from './Pages/Login';
import Countries from './components/Countries';
import Admin from './Private/admin';
import Media from './components/Media';
import Team from "./components/Team";
import Boxes from "./components/Boxes";
import Box from "./components/Box";
import GoogleMerchant from "./components/GoogleMerchant";
import GoogleMerchants from "./components/GoogleMerchants";
import ProductsImport from "./components/ProductsImport";
import ProductsAction from "./components/ProductsAction";
import Products from "./Pages/Products";
import Product from "./components/Product";
import Orders from "./Pages/Orders";
import Order from "./components/Order";
import OrderItems from "./Pages/OrderItems";
import UserOrders from "./components/UserOrders";
import Stores from "./components/Stores";
import Coupons from "./components/Coupons";
import Store from "./components/Store";
import Refund from "./components/Refund";
import Refunds from "./components/Refunds";
import Inventory from "./Pages/Inventory";
import ManageKeys from "./Pages/ManageKeys";
import Shipments from "./components/Shipments";
import NotFound from './NotFound';
import './App.css';

function App() {
  return (
    <div className="App">

      <Router>
        <Switch>
          <Route exact path="/">
            <Admin component={Dashboard} />
          </Route>
          <Route exact path="/items">
            <Admin component={OrderItems} />
          </Route>
          <Route exact path="/shipments">
            <Admin component={Shipments} />
          </Route>
          <Route exact path="/refunds">
            <Admin component={Refunds} />
          </Route>
          <Route exact path="/inventory">
            <Admin component={Inventory} />
          </Route>
          <Route exact path="/boxes">
            <Admin component={Boxes} />
          </Route>
          <Route exact path="/box/:id/:number">
            <Admin component={Box} />
          </Route>
          <Route exact path="/manage-keys">
            <Admin component={ManageKeys} />
          </Route>
          <Route exact path="/stores">
            <Admin component={Stores} />
          </Route>
          <Route exact path="/coupons">
            <Admin component={Coupons} />
          </Route>
          <Route exact path="/store/:id">
            <Admin component={Store} />
          </Route>
          <Route exact path="/store/:storeId/:import">
            <Admin component={ProductsImport} />
          </Route>
          <Route exact path="/google-merchant/:action/:merchant_id">
            <Admin component={ProductsAction} />
          </Route>
          <Route exact path="/products">
            <Admin component={Products} />
          </Route>
          <Route exact path="/google-merchant/:merchant_id">
            <Admin component={GoogleMerchant} />
          </Route>
          <Route exact path="/google-merchants">
            <Admin component={GoogleMerchants} />
          </Route>
          <Route exact path="/product/:id">
            <Admin component={Product} />
          </Route>

          <Route exact path="/orders">
            <Admin component={Orders} />
          </Route>
          <Route exact path="/orders/user/:id">
            <Admin component={UserOrders} />
          </Route>
          <Route exact path="/order/:id">
            <Admin component={Order} />
          </Route>
          <Route exact path="/refund/:id">
            <Admin component={Refund} />
          </Route>

          <Route exact path="/media">
            <Admin component={Media} />
          </Route>

          <Route exact path="/countries/:route">
            <Admin component={Countries} />
          </Route>

          <Route exact path="/users">
            <Admin component={Users} />
          </Route>
          <Route exact path="/team">
            <Admin component={Team} />
          </Route>

          <Route exact path="/login">
            <Login />
          </Route>

          <Route>
            <Admin component={NotFound} />
          </Route>

        </Switch>
      </Router>

    </div>
  );
}

export default App;
